<template>
  <v-container
    id="alerts"
    fluid
    tag="section"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-dialog
        v-model="carga"
        persistent
        width="350"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="text-h4 font-weight-light white--text">
            {{ textoMensaje }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-navigation-drawer
        absolute
        width="100%"
        permanent
      >
        <div
          class="top-main d-flex flex-column align-center justify-center"
        >
          <v-img
            src="assets/logo_la_n.png"
            max-width="250"
            @click="$router.push(
              { path: '/data' },
              () => {
                $emit('reload')
              },
            )"
          />
        </div>
        <div>
          <v-dialog
            v-model="dialogConsulta"
            width="95%"
            persistent
            transition="dialog-bottom-transition"
          >
            <base-material-card
              color="primary"
              icon="mdi-star"
              title="Expedientes "
            >
              <br>
              <v-row>
                <v-col
                  cols="12"
                  md="1"
                  class="px-10"
                >
                  <v-btn
                    tile
                    block
                    color="green"
                    @click="generarExcel()"
                  >
                    <v-icon>
                      mdi-file-excel
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-spacer />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  class="px-10"
                >
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar..."
                    hide-details
                    class="ma-0"
                    style="max-width: 750px;"
                  />
                </v-col>
              </v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="dataConsulta"
                    :search="search"
                    dense
                    light
                    :sort-desc="false"
                    class="elevation-1"
                    fixed-header
                    height="300px"
                    :loading="carga"
                    loading-text="Cargando, por favor espere"
                    :footer-props="{
                      'items-per-page-options': [25, 50, -1],
                      'items-per-page-text': 'Mostrando:',
                      'items-per-page-all-text': 'Todos'
                    }"
                    :header-props="{
                      'sortByText': 'Ordenar por'
                    }"
                    no-data-text="No hay datos registrados"
                    no-results-text="No se encontraron resultados"
                  />
                </v-card-text>
              </v-col>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  block
                  @click="dialogConsulta = false"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </base-material-card>
          </v-dialog>
        </div>
        <v-row
          v-if="!carga"
          justify="center"
          no-gutters
        >
          <base-material-card
            width="550"
            max-width="85%"
            icon="mdi-clock-alert"
            color="primary"
            title="SIN IMPULSO (PENAL)"
          >
            <span class="py-4 text-caption">
              Consulta sobre expedientes sin impulso procesal en los siguientes actos de materia penal:
              <ul>
                <li>Formalización de investigación preparatoria (120 días)</li>
                <li>Acusación directa (20 días)</li>
                <li>Proceso inmediato (20 días)</li>
                <li>Cuaderno de debate (90 días)</li>
              </ul>
              Se mostrará información como juzgado, secretario, expediente, ubicación, estado, días transcurridos y sumilla.
              <br>Para realizar la búsqueda seleccione la opción deseada.
            </span>
            <v-col
              cols="12"
              md="11"
              class="py-4 mt-n2"
            >
              <v-autocomplete
                v-model="actoSeleccionado"
                :items="itemsActos"
                hide-no-data
                hide-selected
                item-text="nombre"
                label="Tipo de acto a consultar"
                placeholder="Escriba o seleccione el tipo de acto"
                prepend-icon="mdi-database-search"
                :rules="regla"
                chips
                dense
                small-chips
                return-object
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="my-n6 py-n2"
            >
              <v-btn
                color="primary"
                block
                class="py-n4"
                @click="consultarData()"
              >
                consultar
              </v-btn>
              <br>
            </v-col>
          </base-material-card>
        </v-row>
        <div
          class="text-body-2 white--text text-center"
          @click="$router.push('/cerrar_sesion')"
        >
          {{ new Date().getFullYear() }} &copy; CSJLA
        </div>
      </v-navigation-drawer>
    </v-form>
  </v-container>
</template>

<script>
  import axios from 'axios'
  const lodash = require('lodash')
  export default {
    name: 'Menu',
    data: () => ({
      carga: true,
      model: null,
      search: '',
      itemsFiltro: [{ nombre: 'Secretario y juzgado', cod: 1 }, { nombre: 'Juzgado y tipo de documento', cod: 2 }, { nombre: 'Juzgado', cod: 3 }],
      items: [],
      itemsActos: [],
      actoSeleccionado: '',
      regla: [(v) => !!v || 'Campo requerido'],
      fechasConsulta: [],
      fechaInicio: '',
      fechaFin: '',
      modalFechaInicio: false,
      modalFechaFin: false,
      dialogConsulta: false,
      dataConsulta: [],
      expanded: [],
      listaSecretarios: [],
      dataAgrupada: [],
      dataInicial: [],
      textoMensaje: 'CARGANDO PÁGINA',
      headers: [{
                  text: 'Juzgado',
                  value: 'x_nom_instancia',
                  width: 400,
                },
                {
                  text: 'Secretario',
                  value: 'x_secretario',
                  width: 360,
                },
                {
                  text: 'Exp.',
                  value: 'x_formato',
                  width: 240,
                },
                {
                  text: 'Ubicación',
                  value: 'x_desc_ubicacion',
                },
                {
                  text: 'Estado',
                  value: 'x_desc_estado',
                  width: 160,
                },
                {
                  text: 'Dias trans.',
                  value: 'dias',
                },
                {
                  text: 'Sumilla',
                  value: 'x_sumilla',
                  width: 380,
                }],
      tipoData: 1,
      tituloDialog: '',
      filtroSeleccionado: '',
      switchSecretario: false,
      secretarioSeleccionado: '',
    }),
    created () {
      this.$store.commit('SET_LOGO', true)
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', true)
      this.itemsActos = []
      setTimeout(() => { this.carga = false }, 1500)
      this.leerActos()
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      getFiltroSeleccionado () {
        this.tipoData = 2
        if (this.filtroSeleccionado === 1) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `"${item.x_nom_instancia}+${item.x_usuario}"`), 'secretario')
        } else if (this.filtroSeleccionado === 2) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `"${item.x_nom_instancia}+${item.x_tipo_doc}"`), 'tipo')
        } else if (this.filtroSeleccionado === 3) {
          this.tipoData = 3
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, 'x_nom_instancia'), 'juzgado')
        }
      },
      limpiarCampos () {
        this.actoSeleccionado = ''
        this.secretarioSeleccionado = ''
      },
      generarExcel () {
        this.itemsXls = this.dataConsulta.map(obj => {
          const rObj = {}
          rObj.Juzgado = obj.x_nom_instancia
          rObj.Secretario = obj.x_secretario
          rObj.Expediente = obj.x_formato
          rObj.Ubicación = obj.x_desc_ubicacion
          rObj.Estado = obj.x_desc_estado
          rObj['Dias transcurridos'] = obj.dias
          rObj.Sumilla = obj.x_sumilla
          return rObj
        })
        this.exportarExcel()
      },
      exportarExcel () {
        const XLSX = require('xlsx')
        const data = XLSX.utils.json_to_sheet(this.itemsXls)
        const workbook = XLSX.utils.book_new()
        const filename = 'reporteSinImpulso'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      },
      consultarData () {
        if (!this.actoSeleccionado) {
          alert('SELECCIONE EL TIPO DE ACTO')
        } else {
          if (this.$refs.form.validate()) {
            this.textoMensaje = 'CARGANDO INFORMACIÓN DEL SISTEMA'
            this.carga = true
            const data = {
              dias: this.actoSeleccionado.dias,
              sumilla: this.actoSeleccionado.palabra_busqueda,
            }
            axios
              .post(
                `${window.__env.dataCfg.urlApiSIJWS}expedientes/expedientesSinImpulso`, data,
              )
              .then((response) => {
                if (response.data.status === 'shido') {
                  this.tituloDialog = 'Información de la búsqueda'
                  this.carga = false
                  // const result = response.data.data
                  this.dataConsulta = response.data.data
                  this.dialogConsulta = true
                  this.dataInicial = this.dataConsulta
                  this.tipoData = 1
                  this.actoSeleccionado = ''
                } else {
                  alert('NO SE ENCONTRÓ LA INFORMACIÓN DE DE LOS JUZGADOS EN LAS FECHAS SELECCIONADAS')
                  this.carga = false
                }
              })
              .catch((e) => {
                alert('ERROR EN LA CONSULTA A LA BASE DE DATOS, INTENTE NUEVAMENTE MÁS TARDE')
                this.carga = false
                console.log(e); // eslint-disable-line
              })
          }
        }
      },
      leerTodo () {
        this.dataConsulta = this.dataInicial
      },
      leerActos () {
        axios
          .post(
            `${window.__env.dataCfg.urlApiData}produccion/impulso/`,
          )
          .then((response) => {
            if (response.data.resp === true) {
              this.carga = false
              this.itemsActos = response.data.array
            } else {
              alert('NO SE ENCONTRÓ LA INFORMACIÓN DE DE LOS JUZGADOS EN LAS FECHAS SELECCIONADAS')
              this.carga = false
            }
          })
          .catch((e) => {
            alert('ERROR EN LA CONSULTA A LA BASE DE DATOS, INTENTE NUEVAMENTE MÁS TARDE')
            this.carga = false
                console.log(e); // eslint-disable-line
          })
      },
      cerrarSesion () {
        var logOut = confirm('¿Desea cerrar sesión?')
        if (logOut === true) {
          localStorage.removeItem('usuarioDataCSJLA')
          localStorage.removeItem('accesoDataJud')
          this.$router.push('/')
        }
      },
    },
  }
</script>
